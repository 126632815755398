import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import HeadingPrimary from '../components/heading-primary';
import TeacherDesktop from '../components/teachers-desktop';

const Teachers = () => (
  <Layout>
    <SEO title="Oktatóink" />
    <HeadingPrimary>Oktatóink</HeadingPrimary>
    <TeacherDesktop />
  </Layout>
);

export default Teachers;
