import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import { colors, fonts, breakPoints } from '../theme/variables';

const StyledTeacherDesktop = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
`;

const TeacherList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 30%;

  @media (max-width: ${breakPoints.first}) {
    display: none;
  }

  ${props =>
    props.tablet &&
    `
    display: none;
    @media (max-width: ${breakPoints.first}) {
      display: block;
      width: 87.5vw;
    }
  `}
`;

const Teacher = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: ${colors.black};
  border: 1px solid ${colors.black};
  border-radius: 3px;
  padding: 1.5em;

  & + & {
    margin-top: 2em;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: ${colors.black};
  border: 1px solid ${colors.black};
  border-right: none;
  border-radius: 3px 0 0 3px;
  padding: 1em 0;
  height: 87px;
  overflow: hidden;

  ${props =>
    props.phone &&
    `
    border-right: none;
    border-left: none;
    border-radius: 0;
  `}

  ${props =>
    props.car &&
    `
    border-left: none;
    border-right: 1px solid ${colors.black};
    border-radius: 0 3px 3px 0;
  `}

  & + & {
    margin-top: 1.25em;
  }
`;

const InfoTablet = styled.div`
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: 1.25em;
  }
`;

const InfoTitle = styled.span`
  font-family: ${fonts.sourceSansPro};
  font-size: 2.2rem;
  line-height: 1.25em;
`;

const InfoData = styled.strong`
  font-family: ${fonts.sourceSansPro};
  font-weight: 700;
  font-size: 2.6rem;
`;

const TeacherDesktop = ({ data }) => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          sort: { fields: [frontmatter___name], order: ASC }
          filter: { fileAbsolutePath: { regex: "/teachers/" } }
        ) {
          nodes {
            id
            frontmatter {
              name
              phone
              car
            }
          }
        }
      }
    `}
    render={data => {
      const { allMarkdownRemark } = data;
      const { nodes } = allMarkdownRemark;
      return (
        <StyledTeacherDesktop>
          <TeacherList>
            {nodes.map(node => {
              const { frontmatter, id } = node;
              const { name } = frontmatter;
              return (
                <Info key={id}>
                  <InfoTitle>Név:</InfoTitle>
                  <InfoData>{name}</InfoData>
                </Info>
              );
            })}
          </TeacherList>
          <TeacherList>
            {nodes.map(node => {
              const { frontmatter, id } = node;
              const { phone } = frontmatter;
              return (
                <Info key={id} phone>
                  <InfoTitle>Telefonszám:</InfoTitle>
                  <InfoData>{phone}</InfoData>
                </Info>
              );
            })}
          </TeacherList>
          <TeacherList>
            {nodes.map(node => {
              const { frontmatter, id } = node;
              const { car } = frontmatter;
              return (
                <Info key={id} car>
                  <InfoTitle>Autó:</InfoTitle>
                  <InfoData>{car}</InfoData>
                </Info>
              );
            })}
          </TeacherList>
          <TeacherList tablet>
            {nodes.map(node => {
              const { frontmatter, id } = node;
              const { name, phone, car } = frontmatter;
              return (
                <Teacher key={id}>
                  <InfoTablet>
                    <InfoTitle>Név:</InfoTitle>
                    <InfoData>{name}</InfoData>
                  </InfoTablet>
                  <InfoTablet>
                    <InfoTitle>Telefonszám:</InfoTitle>
                    <InfoData>{phone}</InfoData>
                  </InfoTablet>
                  <InfoTablet>
                    <InfoTitle>Autó:</InfoTitle>
                    <InfoData>{car}</InfoData>
                  </InfoTablet>
                </Teacher>
              );
            })}
          </TeacherList>
        </StyledTeacherDesktop>
      );
    }}
  />
);

export default TeacherDesktop;
